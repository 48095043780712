<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <MessageFilter v-model="language" />
    </TheSubheader>

    <!-- PAGINATED CONTENT -->
    <div class="mt-12 mb-32 md:mx-[80px] max-md:mx-16 min-w-fit">
      <!-- PAGINATOR -->
      <div class="flex flex-row justify-between" v-if="messages && messages.content && messages.content.length > 0">
        <div class="my-16 flex items-center justify-between">
          <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="messages.content.length" />
        </div>

        <div class="w-48">&nbsp;</div>
        <div class="basis-128 item-center justify-center">
          <AgPaginator v-model="currentPage" :total-pages="messages.totalPages" class="my-24" />
        </div>

        <!-- UPDATE MESSAGES -->
        <div class="flex item-center justify-end">
          <AgButton variant="ghost" iconBeforeText @click="reload()">
            <template #icon>
              <IcUpdate height="28" width="28" />
            </template>
            <div class="text-base">{{ t('messages.update') }}</div>
          </AgButton>
        </div>
      </div>

      <div v-else>
        <AgAlert variant="info">
          {{ t('messages.notFound') }}
        </AgAlert>
      </div>

      <!-- MESSAGE ITEMS -->
      <MessageTable :messages="messages.content" @update:reload-messages="reload()" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import MessageFilter from '@/modules/messages/components/MessageFilter.vue';
  import MessageTable from '@/modules/messages/components/MessageTable.vue';
  import { useMessageStore } from '@/modules/messages/stores/useMessageStore';
  import { i18n } from '@/plugins/i18n';
  import { PageRequest } from '@/util/Paging';
  import { storeToRefs } from 'pinia';
  import { ref, watch } from 'vue';

  const { locale } = i18n.global;
  const { t } = i18n.global;

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.enableHierarchy(false);
  theHeader.title('messages.messages');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const language = ref(locale.value);
  const currentPage = ref(1);
  const pageSize = ref(10);

  const store = useMessageStore();
  const { messages } = storeToRefs(store);
  const { loadData } = store;

  const reload = function () {
    loadData(language.value, new PageRequest(pageSize.value, currentPage.value));
  };

  watch(
    [language, currentPage],
    () => {
      loadData(language.value, new PageRequest(pageSize.value, currentPage.value));
    },
    {
      immediate: true,
    }
  );
</script>
