<template>
  <AgModal v-if="internalValue" fit-height>
    <template #header>
      <div class="flex justify-between">
        <div class="text-2xl font-bold">{{ t('orders.addArticle') }}</div>
        <AgButton v-if="!config.company.slimShoppingCart" variant="ghost" class="text-link" @click.stop="emptyAll">
          {{ t('orders.empty') }}
          <template #icon><IcDelete /></template>
        </AgButton>
      </div>

      <AgTabSwitch v-if="order?.customer.id">
        <AgTab v-model="selectedTab" :value="SelectionType.ALL" dynamic-width>
          <template #title>
            {{ t('orders.all') }}
          </template>
        </AgTab>
        <AgTab v-model="selectedTab" :value="SelectionType.TOP_SELLERS" dynamic-width>
          <template #title>{{ t('orders.topSellers') }}</template>
        </AgTab>
        <AgTab v-model="selectedTab" :value="SelectionType.LATESTS" dynamic-width>
          <template #title>{{ t('orders.latests') }}</template>
        </AgTab>
      </AgTabSwitch>
      <!-- SEARCH -->
      <div>
        <AgFormGroup v-if="!config.company.slimShoppingCart" :label="t('orders.articleType')" class="w-full">
          <AgSearchSelect
            :options="articleTypesOptions"
            :placeholder="t('orders.search')"
            v-model="articleTypeId"
            @update:model-value="filterForArticleType"
            :disabled="selectedTab !== SelectionType.ALL"
          />
        </AgFormGroup>
        <AgFormGroup v-if="!config.company.slimShoppingCart" :label="t('orders.search')" class="w-full">
          <AgSearchSelect
            :options="articleOptions"
            :placeholder="t('orders.search')"
            v-model="articleId"
            :disabled="selectedTab !== SelectionType.ALL"
          />
        </AgFormGroup>
        <AgFormGroup v-else :label="t('orders.search')" class="w-full">
          <AgSearchInput v-model="articleOpenFilter" />
        </AgFormGroup>
      </div>
    </template>

    <template #content>
      <div
        v-if="Object.values(selectedArticlesToShow).length > 0 && !config.company.slimShoppingCart"
        class="flex flex-col h-[calc(100vh-26em)]"
      >
        <div v-for="key in Object.keys(selectedArticlesToShow)" :key="key" class="px-4 my-8">
          <div v-if="selectedArticlesToShow[key].size > 0" class="elementNumber">
            <span class="text-neutral-550">{{ key }}&nbsp;/&nbsp;</span>
            <span class="font-bold">{{ t('orders.elementNumber') }} {{ selectedArticlesToShow[key].size }}</span>
          </div>
          <!-- ARTICLES -->
          <div>
            <div v-for="article in selectedArticlesToShow[key].values()" :key="article.id" class="row">
              <OrderArticleTableRow
                class="w-full"
                v-model="articlesPackaging"
                :article="article"
                :customerId="order?.customer.id ?? -1"
                :agentId="order?.agentId ?? -1"
                searchText=""
                @remove-article="removeArticle(article, key)"
                :isNotAllTab="selectedTab !== SelectionType.ALL"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="Object.values(selectedSlimArticles).length > 0 && config.company.slimShoppingCart"
        class="h-[calc(100vh-20em)]"
      >
        <div class="flex flex-col">
          <div v-for="key in Object.keys(sortedArticles)" :key="key" class="px-4 my-8">
            <div v-for="keyType in Object.keys(sortedArticles[key])" :key="keyType">
              <!-- ARTICLES -->
              <AgCollapsible v-if="sortedArticles[key][keyType]" class="border-b border-neutral-250" showIcon>
                <template #header>
                  <div class="flex justify-between items-center">
                    <div :class="getMatch(key, keyType)" class="flex py-8 gap-8">
                      <span class="elementNumber text-neutral-550"> {{ getTranslatedKey(undefined, keyType) }} </span>
                      <div>
                        <span>{{ getTranslatedKey(key) }}&nbsp;</span>
                        <span class="text-neutral-550">({{ sortedArticles[key][keyType].size }})</span>
                      </div>
                    </div>
                    <div v-if="hasGroupSomeArticles(key, keyType)">
                      <IcCheck class="w-fit fill-success pr-12" />
                    </div>
                  </div>
                </template>
                <template #default>
                  <div v-for="article in sortedArticles[key][keyType].values()" :key="article.id" class="row">
                    <OrderArticleTableRow
                      class="w-full"
                      v-model="articlesPackaging"
                      :article="article"
                      :customerId="order?.customer.id ?? -1"
                      :agentId="order?.agentId ?? -1"
                      :searchText="articleOpenFilter"
                      @remove-article="removeArticle(article, key)"
                    />
                  </div>
                </template>
              </AgCollapsible>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!config.company.slimShoppingCart" class="flex justify-center items-center h-[calc(100vh-26em)]">
        <LogoCart variant="gray" />
      </div>
    </template>
    <template #footer>
      <!-- BUTTON -->
      <AgButton variant="secondary" @click="closeModal">{{ t('orders.cancel') }}</AgButton>
      <div class="flex gap-x-16">
        <AgButton variant="secondary" @click="resetArticles">{{ t('orders.reset') }}</AgButton>
        <AgButton variant="primary" @click="addArticleToOrder" :disabled="isSaveButtonDisabled">
          {{ t('orders.apply') }}
        </AgButton>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
import type { DimensionItemDto } from '@/domain/DimensionItemDto';
import type { ArticlePackaging } from '@/domain/internal/ArticlePackaging';
import type { ContingentEntry } from '@/domain/internal/ContingentEntry';
import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
import IcDelete from '@/components/icons/IcDelete.vue';
import AgButton from '@/components/library/button/AgButton.vue';
import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
import AgModal from '@/components/library/modal/AgModal.vue';
import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
import AgTabSwitch from '@/components/library/tab-switch/AgTabSwitch.vue';
import AgTab from '@/components/library/tab-switch/tab/AgTab.vue';
import LogoCart from '@/components/logo/LogoCart.vue';
import OrderArticleTableRow from '@/modules/orders/components/order-wizard/steps/shopping-cart/article-table-row/OrderArticleTableRow.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
import { useCommon } from '@/composables/useCommon';
import { useTranslatedText } from '@/composables/useTransalteText';
import { config } from '@/config/config';
import { CustomerType } from '@/domain/enumeration/CustomerType';
import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
import { useOrdersStore } from '@/modules/orders/stores/useOrdersStore';
import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
import { i18n } from '@/plugins/i18n';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { isFullReloading } = useMasterData();

  const { getTradeAgreementPriceAndDisconts, getContingentCustomerAndOrderArticle, getArticleCustomerTax } =
    useOrderFactory();
  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);

  const orderStore = useOrdersStore();
  const { refreshContingentMap } = orderStore;
  const { contingentMap } = storeToRefs(orderStore);

  const common = useCommon();

  enum SelectionType {
    ALL = 'all',
    TOP_SELLERS = 'topSellers',
    LATESTS = 'latests',
  }

  const readOnlyArticles = ref<Array<ShoppingCartArticleDto>>([]);
  const filteredArticles = ref<Array<ShoppingCartArticleDto>>([]);
  const topSellersArticles = ref<Array<ShoppingCartArticleDto>>([]);
  const latestsArticles = ref<Array<ShoppingCartArticleDto>>([]);

  const articleId = ref<number>();
  const articleTypeId = ref<number>(-1);
  const articleOpenFilter = ref<string>('');
  const selectedTab = ref<string>(SelectionType.ALL);

  const selectedLatestsArticles = ref<{ [key: string]: Set<ShoppingCartArticleDto> }>({});
  const selectedTopArticles = ref<{ [key: string]: Set<ShoppingCartArticleDto> }>({});
  const selectedArticles = ref<{ [key: string]: Set<ShoppingCartArticleDto> }>({});
  const selectedSlimArticles = ref<{ [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }>({});
  const filteredSlimArticles = ref<{ [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }>({});
  const articlesPackaging = ref<ArticlePackaging>({});

  interface Props {
    modelValue?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const sortedArticles = computed((): { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } } => {
    const keys = Object.keys(filteredSlimArticles.value);

    const articlesWithKeys = keys.flatMap((key1) => {
      return Object.keys(filteredSlimArticles.value[key1]).map((key2) => ({
        key1,
        key2,
        articles: filteredSlimArticles.value[key1][key2],
      }));
    });

    articlesWithKeys.sort((a, b) => {
      const compareKey2 = a.key2.localeCompare(b.key2);
      if (compareKey2 !== 0) {
        return compareKey2;
      }
      return a.key1.localeCompare(b.key1);
    });

    const sortedObject: { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } } = {};
    articlesWithKeys.forEach(({ key1, key2, articles }) => {
      if (!sortedObject[key1]) {
        sortedObject[key1] = {};
      }
      /****************** THIS WORK ONLY FOR THE KELLEREIS BECAUSE THEY HAVE ONLY ONE PACKAGING FOR ARTICLE  ********************* */
      const sortedArticles = Array.from(articles).sort((a, b) => {
        if (a.packagings.length === 0 || b.packagings.length === 0) {
          return 0;
        }
        const aText = `${getTranslatedText(a.packagings[0].unit.title)}_${a.packagings[0].size}`;
        const bText = `${getTranslatedText(b.packagings[0].unit.title)}_${b.packagings[0].size}`;
        return bText.localeCompare(aText);
      });

      const artset = new Set<ShoppingCartArticleDto>(sortedArticles);

      sortedObject[key1][key2] = artset;
    });

    return sortedObject;
  });

  const selectedArticlesToShow = computed(() => {
    if (selectedTab.value === SelectionType.LATESTS) {
      return selectedLatestsArticles.value;
    } else if (selectedTab.value === SelectionType.TOP_SELLERS) {
      return selectedTopArticles.value;
    } else {
      return selectedArticles.value;
    }
  });

  const hasGroupSomeArticles = function (group: string, type?: string) {
    if (config.company.slimShoppingCart && type) {
      if (!selectedSlimArticles.value[group] || !selectedSlimArticles.value[group][type]) {
        return false;
      }
      const articles = Array.from(selectedSlimArticles.value[group] && selectedSlimArticles.value[group][type]);
      return articles.some((article) => {
        for (const packaging of article.packagings) {
          const formArticle = articlesPackaging.value[article.id][packaging.id];
          if (formArticle.quantity || formArticle.freeQuantity) {
            return true;
          }
        }
        return false;
      });
    } else {
      const articles = Array.from(selectedArticles.value[group]);
      return articles.some((article) => {
        for (const packaging of article.packagings) {
          const formArticle = articlesPackaging.value[article.id][packaging.id];
          if (formArticle.quantity || formArticle.freeQuantity) {
            return true;
          }
        }
        return false;
      });
    }
  };

  const closeModal = function () {
    internalValue.value = false;
    selectedArticles.value = {};
    selectedLatestsArticles.value = {};
    selectedTopArticles.value = {};
    filteredSlimArticles.value = {};
    refreshContingentMap();
    articleId.value = undefined;
    articleOpenFilter.value = '';
    articleTypeId.value = -1;
  };

  const addArticleToOrder = async function () {
    if (!order.value) {
      return;
    }

    let articlesToInsert: ShoppingCartArticleDto[] = [];
    if (config.company.slimShoppingCart) {
      articlesToInsert = Object.values(selectedSlimArticles.value)
        .map((value) => Object.values(value))
        .flat()
        .map((value) => Array.from(value))
        .flat();
    } else {
      articlesToInsert = Object.values(selectedArticles.value)
        .map((value) => Array.from(value))
        .flat();
    }

    const formArticle: ArticlePackaging = articlesPackaging.value;

    if (articlesToInsert.length === 0) {
      return;
    }
    for (const article of articlesToInsert) {
      for (const packaging of article.packagings) {
        const quantity = formArticle[article.id][packaging.id].quantity;
        const freeQuantity = formArticle[article.id][packaging.id].freeQuantity;
        const productionDescription = formArticle[article.id][packaging.id].productionDescription ?? '';
        const dimensions = formArticle[article.id][packaging.id].dimensions ?? [];
        const requestedPartialDelivery = formArticle[article.id][packaging.id].requestedPartialDelivery;

        if (quantity || freeQuantity) {
          const articleFound =
            config.company.productionArticle && config.company.productionArticleTypeCode === article.articleType?.code
              ? orderProductionArticle(article.id, packaging.id, productionDescription, dimensions)
              : orderContainArticle(article.id, packaging.id, dimensions);

          let newQuantity = quantity ?? 0;
          if (articleFound) {
            newQuantity += articleFound.quantity ?? 0;
          }
          // Add first dimension value config id if present
          const dimValConfId = dimensions && dimensions.length > 0 ? dimensions[0].value.id : undefined;
          // GET Trade Agreement and discounts
          const taDiscountLists = await getTradeAgreementPriceAndDisconts(
            order.value,
            article.id,
            article.priceGroupIds,
            newQuantity,
            dimValConfId
          );
          const tradeAgreement = taDiscountLists.tradeAgreement;
          const discounts = taDiscountLists.discounts;
          const price = taDiscountLists.price;

          const isPromotional = tradeAgreement && tradeAgreement.customerType === CustomerType.PROMOTION ? true : false;

          if (articleFound) {
            const qt = articleFound.quantity ?? 0;
            const fqt = articleFound.freeQuantity ?? 0;

            articleFound.quantity = qt + (quantity || 0);
            articleFound.freeQuantity = fqt + (freeQuantity || 0);
            articleFound.requestedPartialDelivery = requestedPartialDelivery;

            articleFound.article.productionDescription = productionDescription;
            articleFound.article.dimensions = dimensions;

            articleFound.unitPrice = getUnitPrice(price, discounts);
            articleFound.tradeAgreement = {
              id: tradeAgreement?.id ?? -1,
              price: price,
              isPromotional: isPromotional,
              editedPrice: undefined,
            };
            if (discounts.length > 0) {
              type objectKey = keyof typeof articleFound;
              for (let i = 0; i < discounts.length; i++) {
                const key = `discount${i + 1}` as objectKey;
                setObjProperty(articleFound, key, discounts[i]);
              }
            } else {
              type objectKey = keyof typeof articleFound;
              for (let i = 0; i < config.company.maxItemDiscounts; i++) {
                const key = `discount${i + 1}` as objectKey;
                if (articleFound[key]) {
                  delete articleFound[key];
                }
              }
            }
          } else {
            order.value.items.push({
              note: '',
              requestedPartialDelivery: requestedPartialDelivery,
              article: {
                id: article.id,
                code: article.code,
                type: article.type,
                unit: article.unit,
                externalCode: article?.externalCode,
                articleTaxCode: article.articleTaxCode??undefined,
                productionDescription: productionDescription,
                dimensions: dimensions,
                priceGroupIds: article.priceGroupIds,
                contingentGroupId: article.contingentGroupId,

                sizeForNumber: article.sizeForNumber,
                sizeForWeight: article.sizeForWeight,

                title: article.title,

                articleGroupId: article.articleGroup?.id,
                articleGroup: article.articleGroup?.title,

                articleType: article.articleType?.title,
                articleTypeCode: article.articleType?.code,
              },
              packaging,
              tradeAgreement: {
                id: tradeAgreement?.id ?? -1,
                price: price,
                isPromotional: isPromotional,
                editedPrice: undefined,
              },
              quantity: quantity || 0,
              freeQuantity: freeQuantity || 0,
              finalPrice: getFinalPrice(price, discounts, packaging.size, quantity ?? 0),
              unitPrice: getUnitPrice(price, discounts),
              isSomeDiscountEdited: false,
              isTradeAgreementPriceEdited: false,
              tax: await getArticleCustomerTax(order.value.customer.customerTaxCode, article.id),
              ...Object.fromEntries(discounts.map((value, i) => [`discount${i + 1}`, value])),
            });
          }
        }
      }
    }
    closeModal();
  };

  const getUnitPrice = function (price: number, discounts: number[]) {
    let discountedPrice = price;
    if (discounts.length > 0) {
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        if (discount > 0) {
          discountedPrice = discountedPrice * (1 - discount / 100);
        }
      }
    }
    return discountedPrice;
  };

  const getFinalPrice = function (price: number, discounts: number[], packagingSize: number, quantity: number) {
    const unitPrice = getUnitPrice(price, discounts);
    const finalPrice = unitPrice * packagingSize * quantity;

    return finalPrice;
  };

  const setObjProperty = function <T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    obj[key] = value;
  };

  const orderContainArticle = (articleId: number, packagingId: number, dimensions: Array<DimensionItemDto>) => {
    if (order.value) {
      return order.value.items.find(
        (item) =>
          item.article.id === articleId &&
          item.packaging.id === packagingId &&
          compareArrayDimensions(item.article.dimensions, dimensions)
      );
    }
    return null;
  };

  const orderProductionArticle = (
    articleId: number,
    packagingId: number,
    description: string,
    dimensions: Array<DimensionItemDto>
  ) => {
    if (order.value) {
      return order.value.items.find(
        (item) =>
          item.article.id === articleId &&
          item.packaging.id === packagingId &&
          item.article.productionDescription === description &&
          compareArrayDimensions(item.article.dimensions, dimensions)
      );
    }
    return null;
  };

  const compareArrayDimensions = (a?: Array<DimensionItemDto>, b?: Array<DimensionItemDto>) => {
    return a && b && a.length === b.length && a.every((adim) => b.some((bdim) => adim.value.id === bdim.value.id));
  };

  const groupingArticles = function () {
    const article = readOnlyArticles.value.find((article) => article.id === articleId.value);
    if (article) {
      const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
      if (selectedArticles.value[group]) {
        selectedArticles.value[group].add(article);
      } else {
        selectedArticles.value[group] = new Set();
        selectedArticles.value[group].add(article);
      }
      articleId.value = undefined;
    }
  };

  const articleOptions = computed((): Array<AgSearchSelectOption> => {
    return filteredArticles.value.map((a) => {
      const label: string[] = [a.code];

      const articleGroup = getTranslatedText(a.articleGroup?.title);
      const articleType = getTranslatedText(a.articleType?.title);
      if (articleGroup.length > 0 && articleType.length > 0) {
        label.push(`${articleGroup} (${articleType})`);
      } else if (articleGroup.length > 0) {
        label.push(articleGroup);
      } else if (articleType.length > 0) {
        label.push(articleType);
      }
      const title = getTranslatedText(a.title);
      if (title.length > 0) {
        label.push(title);
      }
      return {
        value: a.id,
        label: label.join(' - '),
        searchableString: a.code + (articleType ?? '') + title + a.searchName,
        disabled: !a.code,
      };
    });
  });

  const articleTypesOptions = computed((): Array<AgSearchSelectOption> => {
    const opts: Array<AgSearchSelectOption> = [];
    readOnlyArticles.value.map((a) => {
      if (!opts.some((o) => o.value === a.articleType?.id) && a.articleType) {
        opts.push({
          value: a.articleType.id,
          label: getTranslatedText(a.articleType.title),
          searchableString: a.articleType.code + getTranslatedText(a.articleType?.title),
        });
      }
    });

    opts.sort((a, b) => a.label.localeCompare(b.label));

    opts.push({
      value: -1,
      label: t('orders.all'),
      searchableString: t('orders.all'),
    });
    return opts;
  });

  const filterForArticleType = function () {
    if (articleTypeId.value === -1) {
      filteredArticles.value = readOnlyArticles.value;
    } else {
      filteredArticles.value = readOnlyArticles.value.filter((a) => a.articleType?.id === articleTypeId.value);
    }
  };

  const loadArticles = async function () {
    readOnlyArticles.value = [];
    if (order.value) {
      const customerId = order.value.customer.id;
      const priceListId = order.value.customer.priceListId;

      readOnlyArticles.value = await common.getArticlesForCustomer(customerId, priceListId);
      filteredArticles.value = readOnlyArticles.value;
    }
  };

  const removeArticle = function (article: ShoppingCartArticleDto, group: string) {
    if (selectedTab.value === SelectionType.ALL) {
      if (selectedArticles.value[group]) {
        selectedArticles.value[group].delete(article);
        if (selectedArticles.value[group].size === 0) {
          delete selectedArticles.value[group];
        }
        resetPackagings(article);
      }
    } else if (selectedTab.value === SelectionType.TOP_SELLERS) {
      if (selectedTopArticles.value[group]) {
        resetPackagings(article);
      }
    } else if (selectedTab.value === SelectionType.LATESTS) {
      if (selectedLatestsArticles.value[group]) {
        resetPackagings(article);
      }
    }
  };

  const resetPackagings = function (article: ShoppingCartArticleDto) {
    for (const packaging of article.packagings) {
      articlesPackaging.value[article.id][packaging.id] = {
        requestedPartialDelivery: undefined,
        productionDescription: undefined,
        dimensions: undefined,
        quantity: null,
        freeQuantity: null,
      };
    }
  };

  const resetSelecterdArticles = function (toReset: { [key: string]: Set<ShoppingCartArticleDto> }) {
    Object.keys(toReset).forEach((key) => {
      toReset[key].forEach((article) => {
        resetPackagings(article);
      });
    });
  };

  const resetArticles = function () {
    refreshContingentMap();

    if (!config.company.slimShoppingCart) {
      resetSelecterdArticles(selectedArticles.value);
    } else {
      Object.keys(selectedSlimArticles.value).forEach((key) => {
        Object.keys(selectedSlimArticles.value[key]).forEach((keyType) => {
          selectedSlimArticles.value[key][keyType].forEach((article) => {
            for (const packaging of article.packagings) {
              articlesPackaging.value[article.id][packaging.id] = {
                requestedPartialDelivery: false,
                productionDescription: undefined,
                dimensions: undefined,
                quantity: null,
                freeQuantity: null,
              };
            }
          });
        });
      });
    }
  };

  const emptyAll = function () {
    selectedArticles.value = {};

    resetSelecterdArticles(selectedTopArticles.value);
    resetSelecterdArticles(selectedLatestsArticles.value);

    refreshContingentMap();
  };

  const isSaveButtonDisabled = computed(() => {
    if (config.company.slimShoppingCart) {
      return false;
    }
    let returnValue = false;
    if (selectedTab.value !== SelectionType.ALL) {
      if (Object.values(selectedArticles.value).length > 0) {
        Object.values(selectedArticles.value).some((group) => {
          const arrGroup = Array.from(group);
          const returningValue = arrGroup.some((article) => {
            return article.packagings.some((packaging) => {
              const formArticle = articlesPackaging.value[article.id][packaging.id];
              if (
                (formArticle.quantity && formArticle.quantity > 0) ||
                (formArticle.freeQuantity && formArticle.freeQuantity > 0)
              ) {
                returnValue = false;
                return true;
              } else {
                returnValue = true;
                return false;
              }
            });
          });
          return returningValue;
        });
      } else {
        return false;
      }
    } else if (
      selectedTab.value === SelectionType.ALL &&
      Object.values(selectedArticles.value).length > 0 &&
      !config.company.slimShoppingCart
    ) {
      for (const key in selectedArticles.value) {
        selectedArticles.value[key].forEach((article) => {
          let internalReturn = true;
          for (const packaging of article.packagings) {
            const formArticle = articlesPackaging.value[article.id][packaging.id];
            if (
              internalReturn &&
              ((formArticle.quantity && formArticle.quantity > 0) ||
                (formArticle.freeQuantity && formArticle.freeQuantity > 0))
            ) {
              internalReturn = false;
            }
          }
          returnValue = internalReturn || returnValue;
        });
      }
    } else {
      returnValue = true;
    }
    return returnValue;
  });

  const loadContingentItemInCart = function () {
    if (!order.value) {
      return;
    }
    order.value.items.forEach(async (item) => {
      if (!order.value || !item.article.dimensions) {
        return;
      }
      const dimCode = config.company.contingentDimensionCode;
      const dimId = item.article.dimensions.find((d) => d.code === dimCode)?.value.id ?? -1;
      if (dimId === -1) {
        console.warn('WARING: no contingent dimensions code found !!');
        return;
      }
      const contingent = await getContingentCustomerAndOrderArticle(
        dimId,
        order.value.customer.id,
        item.article,
        order.value.agentId
      );
      if (contingent) {
        const oldCont = contingentMap.value.get(contingent.id);
        const newEntry: ContingentEntry = {
          originalQuantity: 0,
          available: 0,
        };
        if (oldCont) {
          oldCont.available -= (item.quantity ?? 0) + (item.freeQuantity ?? 0);
        } else {
          newEntry.originalQuantity = contingent.availableQuantity;
          newEntry.available = contingent.availableQuantity - ((item.quantity ?? 0) + (item.freeQuantity ?? 0));
          contingentMap.value.set(contingent.id, newEntry);
        }
      }
    });
  };

  const getMatch = function (ref?: string, ref2?: string) {
    if (ref && articleOpenFilter.value.length > 1) {
      const regexp = new RegExp(articleOpenFilter.value, 'i'); //WATCH IF CAN BE UNIFIED WITH THE REGEX METHODS OF UTILITIES CLASS
      if (regexp.test(ref) || (ref2 && regexp.test(ref2))) {
        return 'bg-yellow px-4 w-fit';
      }
    }
    return '';
  };

  const filterArticles = function () {
    const filterText = articleOpenFilter.value.trim();
    const typeId = articleTypeId.value;
    const hasFilterText = filterText.length > 1;
    const hasTypeId = typeId !== -1;

    const articlesToGet =
      selectedTab.value === SelectionType.TOP_SELLERS
        ? topSellersArticles.value
        : selectedTab.value === SelectionType.LATESTS
          ? latestsArticles.value
          : filteredArticles.value;

    if ((hasFilterText || hasTypeId) && readOnlyArticles.value.length > 0) {
      const regexp = new RegExp(filterText, 'i'); //WATCH IF CAN BE UNIFIED WITH THE REGEX METHODS OF UTILITIES CLASS

      const articlesFiltered = articlesToGet.reduce(
        (acc: { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }, article) => {
          const matchesFilterText = hasFilterText
            ? regexp!.test(
                [
                  article.code,
                  article.externalCode || '',
                  article.title ? getTranslatedText(article.title) : '',
                  article.searchName,
                  article.articleGroup ? getTranslatedText(article.articleGroup.title) : '',
                  article.articleType ? getTranslatedText(article.articleType.title) : '',
                ].join('')
              )
            : true;

          const matchesTypeId = hasTypeId ? article.articleType?.id === typeId : true;

          if (matchesFilterText && matchesTypeId) {
            const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
            const type = article.articleType ? getTranslatedText(article.articleType.title) : 'Other';

            if (!acc[group]) {
              acc[group] = {};
              if (!acc[group][type]) {
                acc[group][type] = new Set();
              }
            }
            if (!acc[group][type]) {
              acc[group][type] = new Set();
            }
            acc[group][type].add(article);
          }
          return acc;
        },
        {}
      );
      filteredSlimArticles.value = articlesFiltered;
    } else {
      filteredSlimArticles.value = groupByArticleGroupAndType(articlesToGet);
    }
  };

  const groupByArticleGroup = function (toGroup: Array<ShoppingCartArticleDto>) {
    return toGroup.reduce((acc: { [key: string]: Set<ShoppingCartArticleDto> }, article) => {
      const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
      if (acc[group]) {
        acc[group].add(article);
      } else {
        acc[group] = new Set();
        acc[group].add(article);
      }
      return acc;
    }, {});
  };

  const groupByArticleGroupAndType = function (toGroup: Array<ShoppingCartArticleDto>) {
    return toGroup.reduce((acc: { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }, article) => {
      const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
      const type = article.articleType ? getTranslatedText(article.articleType.title) : 'Other';
      if (acc[group]) {
        if (acc[group][type]) {
          acc[group][type].add(article);
        } else {
          acc[group][type] = new Set();
          acc[group][type].add(article);
        }
      } else {
        acc[group] = {};
        acc[group][type] = new Set();
        acc[group][type].add(article);
      }
      return acc;
    }, {});
  };

  const removeArticleWithoutQuantity = function () {
    Object.keys(selectedArticles.value).forEach((key) => {
      const articles = selectedArticles.value[key];
      for (const article of articles) {
        let remove = false;
        const packagings = article.packagings;
        for (const packaging of packagings) {
          if (
            !articlesPackaging.value[article.id][packaging.id].quantity &&
            !articlesPackaging.value[article.id][packaging.id].freeQuantity
          ) {
            remove = true;
            break;
          }
        }
        if (remove) {
          selectedArticles.value[key].delete(article);
        }
        if (selectedArticles.value[key].size === 0) {
          delete selectedArticles.value[key];
        }
      }
    });
  };

  const getTranslatedKey = function (keyGroup?: string, keyType?: string) {
    const articleGroups = readOnlyArticles.value.map((a) => a.articleGroup);
    const articleTypes = readOnlyArticles.value.map((a) => a.articleType);
    if (keyGroup) {
      const group = articleGroups.find((ag) => {
        if (ag && ag.title) {
          return Object.values(ag.title).some((title) => {
            return title === keyGroup;
          });
        }
      });
      if (!group) {
        return '';
      }
      return getTranslatedText(group?.title);
    } else {
      const type = articleTypes.find((at) => {
        if (at && at.title) {
          return Object.values(at.title).some((title) => {
            return title === keyType;
          });
        }
      });
      if (!type) {
        return '';
      }
      return getTranslatedText(type?.title);
    }
  };

  watch(articleOpenFilter, filterArticles, { immediate: false });

  watch(
    isFullReloading,
    async (fullReload) => {
      if (!fullReload) {
        await loadArticles();
      }
    },
    { immediate: true }
  );

  watch(
    articleId,
    (newArticleId) => {
      if (newArticleId) {
        groupingArticles();
      }
    },
    { immediate: true }
  );

  watch(
    /**************  LOGIC FOR THE VAROIUS SHOPPING CART TABS  **************** */
    selectedTab,
    async () => {
      if (!order.value || !order.value.customer.id) {
        return;
      }

      if (selectedTab.value === SelectionType.ALL) {
        filteredArticles.value = readOnlyArticles.value;
        if (!config.company.slimShoppingCart) {
          /*************  LEAVE ONLY THE ARTICLES SELECTED ON THE OTHER TABS   ******************* */
          Object.keys(selectedArticles.value).forEach((key) => {
            const articles = selectedArticles.value[key];

            for (const article of articles) {
              let remove = false;
              const packagings = article.packagings;
              for (const packaging of packagings) {
                if (
                  !articlesPackaging.value[article.id][packaging.id].quantity &&
                  !articlesPackaging.value[article.id][packaging.id].freeQuantity
                ) {
                  remove = true;
                  break;
                }
              }
              if (remove) {
                selectedArticles.value[key].delete(article);
              }
              if (selectedArticles.value[key].size === 0) {
                delete selectedArticles.value[key];
              }
            }
          });
        }
      } else if (selectedTab.value === SelectionType.TOP_SELLERS) {
        topSellersArticles.value = await common.getTopSellers(order.value.customer.id);
        filteredArticles.value = topSellersArticles.value;
        if (!config.company.slimShoppingCart) {
          /*************  GROUP THE TOPSELLERS   ******************* */
          const topSellersGrouped = groupByArticleGroup(topSellersArticles.value);
          selectedTopArticles.value = topSellersGrouped;
          /*************  LEAVE ONLY THE ITEMS SELECTED IN  OTHER TABS WITH SOME QTY   ******************* */
          removeArticleWithoutQuantity();
          /*************  ADD THE TOP SELLERS TO THE SELECTED ARTICLES   ******************* */
          Object.keys(topSellersGrouped).forEach((key) => {
            if (!selectedArticles.value[key]) {
              selectedArticles.value[key] = new Set();
            }
            for (const article of topSellersGrouped[key]) {
              const articlesOfGroup = Array.from(selectedArticles.value[key]);
              const hasArticle = articlesOfGroup.some((art) => art.id === article.id);
              if (!hasArticle) {
                selectedArticles.value[key].add(article);
              }
            }
          });
        }
      } else if (selectedTab.value === SelectionType.LATESTS) {
        latestsArticles.value = await common.getLatestsArticles(order.value.customer.id, 12);
        filteredArticles.value = latestsArticles.value;

        if (!config.company.slimShoppingCart) {
          /*************  GROUP THE LATESTS   ******************* */
          const latestGrouped = groupByArticleGroup(latestsArticles.value);
          selectedLatestsArticles.value = latestGrouped;
          /*************  REMOVE ARTICLES WIITHOUT QUANTITY   ******************* */
          removeArticleWithoutQuantity();
          /*************  ADD THE TOP LATESTS TO THE SELECTED ARTICLES   ******************* */
          Object.keys(latestGrouped).forEach((key) => {
            if (!selectedArticles.value[key]) {
              selectedArticles.value[key] = new Set();
            }
            for (const article of latestGrouped[key]) {
              const articlesOfGroup = Array.from(selectedArticles.value[key]);
              const hasArticle = articlesOfGroup.some((art) => art.id === article.id);
              if (!hasArticle) {
                selectedArticles.value[key].add(article);
              }
            }
          });
        }
      }
      filterArticles();
    },
    { immediate: true }
  );

  watch(
    internalValue,
    (value) => {
      if (value && readOnlyArticles.value) {
        const artPackaging: ArticlePackaging = {};
        for (const article of readOnlyArticles.value) {
          artPackaging[article.id] = {};
          for (const packaging of article.packagings) {
            artPackaging[article.id][packaging.id] = {
              requestedPartialDelivery: undefined,
              productionDescription: undefined,
              dimensions: undefined,
              quantity: null,
              freeQuantity: null,
            };
          }
        }
        selectedTab.value = SelectionType.ALL;
        articlesPackaging.value = artPackaging;
        loadContingentItemInCart();
        if (config.company.slimShoppingCart) {
          selectedSlimArticles.value = groupByArticleGroupAndType(readOnlyArticles.value);
          filteredSlimArticles.value = selectedSlimArticles.value;
        }
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .row {
    @apply flex justify-between items-center;
  }

  .elementNumber {
    @apply flex items-center justify-center rounded-full p-4 w-fit shrink-0 grow-0 border bg-neutral-200 border-neutral-300 px-12 text-s mb-4;
  }
</style>
